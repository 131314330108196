import { useQuery } from '@tanstack/react-query';
import { AnalyticsService } from '../../../../shared/api/model/service/analytics.service';
import { ANALYTICS_QUERY } from '../../../../shared/const/query.const';

export function useAnalyticsQuery() {
  return useQuery({
    queryKey: [ANALYTICS_QUERY],
    queryFn: () => AnalyticsService.fetchAnalytics(),
    select: (data) => data
  });
}
