import React, { PropsWithChildren, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import dayjs from 'dayjs';

import { Container, Content, ExpireTarif, MainContent, Wrapper } from './innerLayout.styled';
import Sidebar from './sidebar/ui/Sidebar';

import { getRouteCalendarCabinet, getRouteCalendarEmployee } from '../../shared/const';
import { cn } from '../../shared/lib';
import { useActions } from '../../shared/lib/hooks/useActions';
import useMatchMedia from '../../shared/lib/hooks/useMatchMedia';
import { useTypedSelector } from '../../shared/lib/hooks/useTypedSelector';
import { Text, TextPType, TextType } from '../../shared/ui';
import { useFilialQuery } from '../../store/redux/filial/hooks/useFilialQuery';
import { useMyBalanceQuery, useMyTarifQuery } from '../../store/redux/tarif/hooks/useTarifQuery';
import { getCookie } from '../../utils/cookies';
import { FlexWithAlign } from '../../utils/styleUtils';
import { EIcon, IconNew as IconInstance } from '../icons/medium-new-icons/icon';
import { useAnalyticsQuery } from '../../store/redux/analytics/hooks/useAnalytics';

interface IProps extends PropsWithChildren {
  children?: React.ReactNode;
}

export const scrollController = {
  scrollPosition: 0,
  disabledScroll() {
    scrollController.scrollPosition = window.scrollY;
    document.body.style.cssText = `
        overflow: hidden; 
        // position: fixed; 
        // top: -${scrollController.scrollPosition}px; 
        // left: 0; 
        // height: 100vh; 
        // width: 100vw; 
        //padding-right: 6px;
      `;
  },
  enabledScroll() {
    document.body.style.cssText = '';
    window.scroll({ top: scrollController.scrollPosition });
  }
};
const InnerLayout: React.FC<IProps> = (props: IProps) => {
  const { children } = props;
  const { data: activeFilial, isLoading: isFilialLoading, isFetching } = useFilialQuery();
  const { data: activeTarif, isLoading } = useMyTarifQuery();
  const { data: balance } = useMyBalanceQuery();
  const { data: analytics } = useAnalyticsQuery();
  console.log(' analytics', analytics);
  // const { activeTarif, balance } = useTypedSelector((state) => state.tarif);
  const { profileUser } = useTypedSelector((state) => state.user);
  const [isMobile] = useMatchMedia(['((max-width: 767px))']);
  const { pathname } = useLocation();
  const pathNameForMessengerAndChatContainer: string = pathname.split('/')[1];

  const {
    fetchUsersAsync,
    // getMyTarifAsync,
    fetchProductsAsync,
    fetchCabinetsAsync,
    fetchUserByIdAsync
    // getMyBalanceAsync
  } = useActions();

  useEffect(() => {
    fetchProductsAsync();
    // getMyTarifAsync();
    fetchUsersAsync();
    fetchUserByIdAsync(getCookie('id'));
    // getMyBalanceAsync();
  }, []);

  useEffect(() => {
    activeFilial && fetchCabinetsAsync(activeFilial.id);
    const fetchData = async () => {
      activeFilial && localStorage.setItem('currentFilialName', activeFilial.name);
    };

    const checkLocalStorage = () => {
      return localStorage.getItem('currentFilialName');
    };

    const performFetchWithRetry = async (maxAttempts: number) => {
      let attempts = 0;

      while (!checkLocalStorage() && attempts < maxAttempts) {
        await fetchData();
        attempts++;
      }
    };

    const maxAttempts = 10;
    performFetchWithRetry(maxAttempts);
  }, [isFilialLoading]);

  return (
    <FlexWithAlign
      $column
      $gap={'0'}
    >
      {!isLoading &&
      activeTarif &&
      dayjs(activeTarif.timeend).isBefore(dayjs()) &&
      balance !== undefined &&
      balance <= 0 &&
      profileUser?.role === 'admin' ? (
        <>
          <ExpireTarif>
            <IconInstance name={EIcon.warning} />
            <Text
              $tag={TextType.P}
              $pSize={isMobile ? TextPType.P11 : TextPType.P13}
            >
              Личный кабинет находится в Режиме просмотра, так как на балансе недостаточно средств. Пополните баланс в разделе{' '}
              <Link to={isMobile ? 'https://dev.telebon.hanumi.net/portalsettings/tarif' : '/subscribe'}>Подписка и биллинг</Link>
            </Text>
          </ExpireTarif>
        </>
      ) : (
        <>{null}</>
      )}

      <Container
        className={cn(
          pathNameForMessengerAndChatContainer === 'messenger'
            ? 'messenger'
            : pathNameForMessengerAndChatContainer === 'clients'
            ? 'clients'
            : ''
        )}
      >
        <MainContent className={cn((pathname === getRouteCalendarEmployee() || pathname === getRouteCalendarCabinet()) && 'none')}>
          <Wrapper className={cn(isMobile && 'bottom')}>
            <Content>{children}</Content>
          </Wrapper>
        </MainContent>
        <Sidebar />
      </Container>
    </FlexWithAlign>
  );
};

export default InnerLayout;
