import React, { memo, useEffect, useRef, useState } from 'react';

import { ClearButton, CurrencyPreffix, ErrorText, IconContainer, InputContainer, InputContent, Label } from './NewInput.styled';

import 'react-datepicker/dist/react-datepicker.css';
import { InputProps } from './NewInput.props';

import { EIcon, IconNew } from '../../../../components/icons/medium-new-icons/icon';
import { cn } from '../../../lib';

import { CurrencyInputOnChangeValues } from 'react-currency-input-field';

import NewText from '../../NewText/ui/NewText';
import { TextCustomType, TextType } from '../../NewText/ui/NewText.props';

export const NewInput = memo((props: InputProps) => {
  const { label, type, autofocus, className, children, value, onChange, name, error = null, isClear, ...rest } = props;
  const { disabled } = rest;
  const [isFocused, setIsFocused] = useState(false);
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (autofocus) {
      setIsFocused(true);
      ref.current?.focus();
    }
  }, [autofocus]);
  const onBlur = () => {
    setIsFocused(false);
  };

  const onFocus = () => {
    setIsFocused(true);
  };
  const handleInputChange = (value: string | undefined, name: string | undefined, values: CurrencyInputOnChangeValues | undefined) => {
    if (name !== undefined) {
      if (value !== undefined) {
        onChange({
          target: {
            value: '0',
            name: name
          }
        } as React.ChangeEvent<HTMLInputElement>);
      }
      onChange({
        target: {
          value: values?.float || '',
          name: name
        }
      } as React.ChangeEvent<HTMLInputElement>);
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedData = clipboardData.getData('text');
    const digitsOnly = pastedData.replace(/\D/g, '');

    let formattedNumber: string;
    if (digitsOnly.length === 10) {
      formattedNumber = `+7 ${digitsOnly.slice(0, 3)} ${digitsOnly.slice(3, 6)}-${digitsOnly.slice(6, 8)}-${digitsOnly.slice(8, 10)}`;
    } else if (digitsOnly.length === 11 && (digitsOnly.startsWith('7') || digitsOnly.startsWith('8'))) {
      formattedNumber = `+7 ${digitsOnly.slice(1, 4)} ${digitsOnly.slice(4, 7)}-${digitsOnly.slice(7, 9)}-${digitsOnly.slice(9, 11)}`;
    } else {
      formattedNumber = digitsOnly;
    }

    onChange({
      target: {
        value: formattedNumber,
        name: name
      }
    } as React.ChangeEvent<HTMLInputElement>);

    event.preventDefault();
  };

  const handleClear = () => {
    if (ref.current) {
      ref.current.value = '';
      ref.current.focus();
      onChange({
        target: {
          value: '',
          name: name
        }
      } as React.ChangeEvent<HTMLInputElement>);
    }
  };

  return (
    <InputContainer
      ref={ref}
      className={cn(isFocused && 'focused', { error: !!error }, className)}
    >
      <Label
        htmlFor={name}
        className={cn(
          {
            active:
              (value && value.length) ||
              (value && Number(value)) > 0 ||
              (type === 'number' && Number(value) === 0) ||
              (type === 'currency' && Number(value) >= 0),
            error: !!error
          },
          children && 'with-icon',
          disabled && 'disabled'
        )}
      >
        {label && <span>{label}</span>}

        {type === 'currency' ? (
          <CurrencyPreffix className={'currencyRubble'}>
            <NewText
              $tag={TextType.H6}
              $customSize={TextCustomType.T14}
              color={'grey'}
              className={'currencySymbol'}
            >
              ₽
            </NewText>
          </CurrencyPreffix>
        ) : children ? (
          <IconContainer
            className={cn({
              active: value && value.length,
              error: !!error
            })}
          >
            {children}
          </IconContainer>
        ) : null}
        <InputContent
          className={cn(
            {
              active: (value && value.length) || (value && Number(value)) > 0 || (type === 'number' && Number(value) === 0),
              error: !!error
            },
            type === 'currency' && 'currency',
            children && 'with-icon',
            isClear && 'with-clear-btn'
          )}
          autoComplete={'off'}
          onFocus={onFocus}
          min={1}
          max={9999999}
          onPaste={type === 'tel' ? handlePaste : () => {}}
          onBlur={onBlur}
          mask={
            type === 'tel'
              ? '+7 999 999-99-99'
              : type === 'dateB'
              ? '99.99.9999'
              : type === 'summ'
              ? '999 999 999,99 ₽'
              : (undefined as any)
          }
          maskChar={null}
          value={value}
          ref={ref}
          onChange={onChange}
          onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
            const inputValue = Number(e.target.value);
            if ((type === 'number' || type === 'currency') && (isNaN(inputValue) || inputValue > 9999999)) {
              e.target.value = Math.min(Number(e.target.value), 9999999).toString();
            } else {
              onChange(e);
            }
          }}
          type={type === 'currency' ? 'number' : type}
          id={name}
          {...rest}
        />
        {isClear && value !== '' ? (
          <ClearButton onClick={handleClear}>
            <IconNew name={EIcon.close} />
          </ClearButton>
        ) : null}
      </Label>

      {error ? <ErrorText className='errorText'>{error}</ErrorText> : null}
    </InputContainer>
  );
});

NewInput.displayName = 'NewInput';
