import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ProductService } from '../../../../shared/api/model/service/product.service';
import { PRODUCTS_QUERY } from '../../../../shared/const/query.const';
import { IProduct } from '../products.interface';

export function useCreateProduct() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (values: Omit<IProduct, 'id'>) => ProductService.createProduct(values),
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [PRODUCTS_QUERY] });
      }
    }
  });
}

export function useUpdateProduct() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (values: IProduct) => ProductService.updateProduct(values),
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [PRODUCTS_QUERY] });
      }
    }
  });
}

export function useDeleteProduct() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => ProductService.deleteProduct(id),
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [PRODUCTS_QUERY] });
      }
    }
  });
}
