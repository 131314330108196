import { IProduct } from '../../../../store/redux/products/products.interface';
import { apiDelete, apiGet, apiPost, apiPut } from '../../interceptors';

export const ProductService = {
  async fetchProducts() {
    const response = await apiGet('/products');

    return response.data && response.data.product ? response.data.product : [];
  },
  async fetchProductById(id: string) {
    const response = await apiGet(`/product/${id}`);

    return response.data && response.data.product ? response.data.product : [];
  },
  async createProduct(values: Omit<IProduct, 'id'>) {
    const response = await apiPut('/product', values);

    return response.data;
  },
  async updateProduct(values: IProduct) {
    const { id, ...data } = values;
    const response = await apiPost(`/product/${id}`, data);
    return response.data;
  },
  async deleteProduct(id: string) {
    const response = await apiDelete(`/product/${id}`);

    return response.data;
  }
};
